<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:260px;"
        placeholder="搜索用户名\手机号"
        v-model="search"
      >
        <el-button
          slot="suffix"
          @click="getDataList"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>

      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="useraccount"
          label="登录用户名"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="realName"
          label="真实姓名"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          min-width="150"
        ></el-table-column>
        <el-table-column
          prop="createtime"
          label="创建时间"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="rolesArr"
          label="所属岗位"
          align="center"
          min-width="160"
        ></el-table-column>
        <!-- <el-table-column prop="dataPermissions" label="数据权限" :formatter="per" align="center" min-width="160"></el-table-column> -->
        <el-table-column
          prop="state"
          label="状态"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              @change="changeSWitchHandler($event, scope.row)"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="340">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button
                 
                type="primary"
                @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button
                 
                type="success"
                @click="editmenuHandler(scope.row)"
                >配置岗位</el-button
              >
              <el-button
                 
                type="danger"
                @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
              <el-button   @click="resetPassword(scope.row.id)"
                >重置密码</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="130px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="登录用户名" prop="realName">
          <el-input
            :disabled="!isAdd"
            maxlength="15"
            v-model="formData.useraccount"
            style="width:300px;"
            placeholder="请输入登录用户名"
            clearable
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="真实姓名" prop="realName">
          <el-input
            maxlength="5"
            v-model="formData.realName"
            style="width:300px;"
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="手机号" prop="realName">
          <el-input
            maxlength="11"
            type="number"
            v-model="formData.mobile"
            style="width:300px;"
            placeholder="请输入手机号"
            clearable
          />
        </el-form-item>
        <!-- <el-form-item class="nav-form-item" label="数据权限" prop="name">
                    <el-select style="width:200px" v-model="formData.dataPermissions" placeholder="请选择">
                        <el-option label="全部" value="1"></el-option>
                        <el-option label="代理商本人" value="2"></el-option>
                        <el-option label="自定义" value="3"></el-option>
                    </el-select>
                </el-form-item> -->
        <p v-show="isAdd" style="text-align: center;">
          用户初始密码：admin123456
        </p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="配置岗位" :visible.sync="menuVisible" width="40%">
      <!-- <el-tree
              ref="menuTree"
              :data="AllParent"
              :props="menuRoleProps"
              :default-checked-keys="selectedMenu"
              node-key="id"
              highlight-current
              default-expand-all
              getCheckedNodes="getSelectedMenu"
              show-checkbox
            ></el-tree>-->
      <el-select
        v-model="roles"
        multiple
        collapse-tags
        style="margin-left: 20px;"
        placeholder="请选择"
      >
        <el-option
          v-for="item in AllParent"
          :key="item.value"
          :label="item.roleName"
          :value="item.roleCode"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="menuVisible = false">取 消</el-button>
        <el-button type="primary" @click="menuConfimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getRoleSlect } from "@/api/role";
import {
  getUserList,
  add,
  updateUser,
  delUser,
  setUserRole,
} from "@/api/sysUser";
import { resetPassword } from "../../api/sysUser";

export default {
  data() {
    return {
      dialogVisible: false,
      menuVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      userId: "",
      roles: [],
      formData: {
        isOperator: 0,
        realName: "",
        useraccount: "",
        mobile: "",
        dataPermissions: "",
      },
      total: 0,

      options: [
        {
          value: "1",
          label: "最近十五天",
        },
        {
          value: "2",
          label: "最近十天",
        },
      ],
      formRule: {
        realName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      AllParent: [],
      menuRoleProps: {
        children: "children",
        label: "menuname",
        value: "id",
      },
      selectedMenu: [],
      popupModalTitle: "新增",
    };
  },
  created() {
    this.getDataList();
    // this.getRoleList()
  },
  methods: {
    per(row) {
      if (row.dataPermissions == 1) {
        return "全部";
      } else if (row.dataPermissions == 2) {
        return "代理商本人";
      } else if (row.dataPermissions == 3) {
        return "自定义";
      }
    },
    resetPassword(userId) {
      console.log(userId);
      resetPassword({ userId: userId }).then((res) => {
        if (res.message.code === 0) {
          this.$message({
            message: "密码重置成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message.message,
            type: "fail",
          });
        }
      });
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getUserList(param).then((res) => {
        if (res.data.records && res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.isOperator = 0;
      this.formData.realName = "";
      this.formData.useraccount = "";
      this.formData.mobile = "";
      this.popupModalTitle = "新增";
      this.formData.dataPermissions = "";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.isOperator = row.isOperator;
      this.formData.realName = row.realName;
      this.formData.useraccount = row.useraccount;
      this.formData.mobile = row.mobile;
      this.formData.id = row.id;
      this.formData.dataPermissions = row.dataPermissions.toString();
      this.popupModalTitle = "编辑";
      this.dialogVisible = true;
    },
    editCard() {
      updateUser(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      add(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message.message);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delUser(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    changeSWitchHandler($event, row) {
      this.formData.state = $event;
      this.formData.useraccount = row.useraccount;
      this.formData.realName = row.realName;
      this.editCard();
    },
    editmenuHandler(row) {
      this.userId = row.id;
      let param = {
        id: row.id,
      };

      getRoleSlect(param).then((res) => {
        console.log(res);
        this.AllParent = [...res.data];
        this.roles = [...[]];
        res.data.forEach((element) => {
          if (element.check) {
            this.roles.push(element.roleCode);
          }
        });
      });
      this.menuVisible = true;
    },
    menuConfimHandler() {
      let param = {
        id: this.userId,
        roleCodes: this.roles,
      };

      setUserRole(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.menuVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>
