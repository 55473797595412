<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:240px;"
        placeholder="搜索岗位名"
        v-model="search"
      >
        <el-button
          slot="suffix"
          @click="getDataList"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>

      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="岗位名称"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="description"
          label="岗位描述"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="260">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button type="success" @click="editMenuHandler(scope.row)"
                >配置菜单</el-button
              >
              <el-button type="danger" @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="130px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="岗位名称" prop="roleName">
          <el-input
            maxlength="15"
            v-model="formData.roleName"
            style="width:300px;"
            placeholder="请输入岗位名称"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="岗位描述" prop="description">
          <el-input
            maxlength="30"
            type="textarea"
            :rows="3"
            v-model="formData.description"
            style="width:300px;"
            placeholder="请输入岗位描述"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="配置菜单" :visible.sync="menuDialogVisible" width="40%">
      <el-cascader
        style="width: 300px;"
        v-model="menus"
        :options="menuTree"
        :props="props"
        collapse-tags
        filterable
        @clear="historyMenus = []"
        clearable
      ></el-cascader>

      <!--      <el-tree-->
      <!--              v-model="menus"-->
      <!--              :data="menuTree"-->
      <!--              show-checkbox-->
      <!--              node-key="id"-->
      <!--              :default-expanded-keys="[2, 3]"-->
      <!--              :default-checked-keys="[5]"-->
      <!--              :props="props">-->
      <!--      </el-tree>-->

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="menuDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimMenuHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoleList,
  addRole,
  delRole,
  editRole,
  setRoleMenu,
} from "@/api/role";
import { difference, cloneDeep } from "lodash-es";

import { getMenuTree, getMenuArr } from "@/api/menu";

export default {
  data() {
    return {
      menus: [],
      historyMenus: [],
      roleId: "",
      dialogVisible: false,
      menuDialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      menuTree: [],
      loading: true,
      formData: {
        id: "",
        roleName: "",
        description: "",
      },

      options: [],
      formRule: {
        roleName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      props: {
        checkStrictly: true,
        value: "menuCode",
        label: "menuName",
        children: "children",
        emitPath: false,
        multiple: true,
      },
      popupModalTitle: "新增",
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getDataList();
    this.getMenuTree();
  },
  methods: {
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getRoleList(param).then((res) => {
        if (res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.dialogVisible = true;
      this.formData.roleName = "";
      this.popupModalTitle = "新增";
      this.formData.description = "";
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.roleName = row.roleName;
      this.formData.description = row.description;
      this.formData.id = row.id;
      this.popupModalTitle = "编辑";
      this.dialogVisible = true;
    },
    editCard() {
      editRole(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      addRole(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delRole(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    editMenuHandler(row) {
      let param = {
        roleCode: row.roleCode,
      };
      this.roleId = row.roleCode;
      this.menuDialogVisible = true;
      this.menus = [];
      this.historyMenus = [];

      getMenuArr(param).then((res) => {
        this.menus = res.data;
        this.historyMenus = res.data;
      });
    },
    confimMenuHandler() {
      let param = {
        roleCode: this.roleId,
        menuCodes: this.menus,
      };
      setRoleMenu(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.menuDialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    getMenuTree() {
      let param = {
        roleId: 0,
      };
      getMenuTree(param).then((res) => {
        if (res.data && res.data.length > 0) {
          this.menuTree = [...res.data];
        }
      });
    },
  },
};
</script>
<style scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>
