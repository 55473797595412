<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="15"
        style="width:240px;"
        placeholder="搜索菜单名"
        v-model="search"
      >
        <el-button
          slot="suffix"
          @click="getDataList"
          type="primary"
          icon="el-icon-search"
          style="border-radius:0 4px 4px 0;margin-right:-5px"
          >搜索</el-button
        >
      </el-input>
      <el-button
        @click="addHandler"
        type="primary"
        style="margin-left:20px;"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80px"
        ></el-table-column>
        <el-table-column
          prop="menuName"
          label="菜单名称"
          align="center"
          min-width="80"
        ></el-table-column>
        <el-table-column
          prop="menuCode"
          label="菜单代码"
          align="center"
          min-width="80"
        ></el-table-column>
        <!--                <el-table-column prop="requestUrl" label="后端路由" align="center" min-width="160"></el-table-column>-->
        <el-table-column
          prop="frontendUrl"
          label="前端路由"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="menuType"
          label="菜单类型"
          :formatter="menuType"
          align="center"
          min-width="160"
        ></el-table-column>
        <el-table-column
          prop="parentName"
          label="父级菜单名称"
          align="center"
          min-width="160"
        ></el-table-column>

        <el-table-column label="操作" align="center" width="160">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <el-button type="danger" @click="deteteHandlet(scope.row)"
                >删除</el-button
              >
              <!--<i style="cursor: pointer;" @click="deteteHandlet(scope.row)" ></i>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="popupModalTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleBeforeClose"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="130px"
        :rules="formRule"
      >
        <el-form-item class="nav-form-item" label="菜单名称" prop="menuname">
          <el-input
            maxlength="15"
            v-model="formData.menuName"
            style="width:300px;"
            placeholder="请输入菜单名称"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="菜单类型" prop="menuname">
          <el-radio-group v-model="formData.menuType">
            <el-radio :label="0">菜单</el-radio>
            <!--                        <el-radio :label="1">按钮</el-radio>-->
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="nav-form-item"
          label="前端路由"
          prop="menuname"
          v-show="formData.menuType == 0"
        >
          <el-input
            maxlength="30"
            v-show="formData.menuType == 0"
            v-model="formData.frontendUrl"
            placeholder="请输入前端路由"
            style="width:300px;"
          />
        </el-form-item>
        <el-form-item
          class="nav-form-item"
          label="后端路由"
          prop="menuname"
          v-show="formData.menuType == 1"
        >
          <el-input
            maxlength="30"
            style="width:300px;"
            v-model="formData.requestUrl"
            placeholder="请输入后端路由"
          />
        </el-form-item>
        <!--                <el-form-item class="nav-form-item" label="菜单图标" prop="menuname">-->
        <!--                    <el-input v-model="formData.icon" placeholder="请输入菜单图标"/>-->
        <!--                </el-form-item>-->
        <el-form-item class="nav-form-item" label="菜单序号" prop="menuname">
          <el-input
            v-model="formData.sequence"
            placeholder="请输入序号"
            style="width:300px;"
          />
        </el-form-item>
        <el-form-item
          class="nav-form-item"
          label="父级菜单代码"
          prop="menuname"
        >
          <el-cascader
            style="width:300px;"
            v-model="formData.parentCode"
            :value="formData.parentCode"
            :options="menuTree"
            :props="props"
            clearable
            filterable
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMenuList,
  delMenu,
  updateMenu,
  getMenuTree,
  addMenu,
} from "@/api/menu";

export default {
  data() {
    return {
      dialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      //   page: 1,
      //   pageSize: 10,
      loading: true,
      formData: {
        sequence: "",
        requestUrl: "",
        frontendUrl: "",
        icon: "",
        menuType: "",
        menucode: "",
        menuName: "",
        parentCode: "",
        id: "",
      },
      //   total: 0,
      menuTree: [],
      options: [],
      formRule: {
        menuName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      props: {
        checkStrictly: true,
        value: "menuCode",
        label: "menuName",
        children: "children",
        emitPath: false,
        expandTrigger: "hover",
      },
      popupModalTitle: "新增",
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getDataList();
    this.getMenuTree();
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    getMenuTree() {
      let param = {
        roleId: 0,
      };
      getMenuTree(param).then((res) => {
        if (res.data && res.data.length > 0) {
          this.menuTree = [...res.data];
        }
      });
    },
    menuType(row) {
      if (row.menuType == 0) {
        return "菜单";
      } else if (row.menuType == 1) {
        return "按钮";
      }
    },
    getDataList() {
      this.loading = true;
      let param = {
        keyWord: this.search,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getMenuList(param).then((res) => {
        if (res.data.records.length >= 0) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
          console.log(this.tableData);
        }

        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.menuType = "";
      this.formData.sequence = "";
      this.formData.menucode = "";
      this.formData.menuName = "";
      this.formData.frontendUrl = "";
      this.formData.requestUrl = "";
      this.formData.parentCode = "";
      this.popupModalTitle = "新增";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
          this.getMenuTree();
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.menuName = row.menuName;
      this.formData.frontendUrl = row.frontendUrl;
      this.formData.requestUrl = row.requestUrl;
      this.formData.menuType = row.menuType;
      this.formData.parentCode = row.parentCode;
      this.formData.sequence = row.sequence;
      this.formData.id = row.id;
      this.formData.menuType = row.menuType;
      this.popupModalTitle = "编辑";
      this.dialogVisible = true;
    },
    editCard() {
      updateMenu(this.formData).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      addMenu(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          id: row.id,
        };
        delMenu(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    handleBeforeClose(done) {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      done();
    },
  },
};
</script>
<style scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}
</style>
